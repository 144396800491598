import React from "react"
import MobilepayIcon from "../assets/mobilepay-icon.svg"
import addToMailchimp from "gatsby-plugin-mailchimp"

export default class membershipBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: "", fullName: "", phone: "", interest: "" }

    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangeFullName = this.handleChangeFullName.bind(this)
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.handleChangeInterest = this.handleChangeInterest.bind(this)
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value })
    document
      .querySelector(".controlMessage")
      .classList.remove("showCodeMessage")
  }
  handleChangeFullName(event) {
    this.setState({ fullName: event.target.value })
    document
      .querySelector(".controlMessage")
      .classList.remove("showCodeMessage")
  }
  handleChangePhone(event) {
    this.setState({ phone: event.target.value })
    document
      .querySelector(".controlMessage")
      .classList.remove("showCodeMessage")
  }
  handleChangeInterest(event) {
    this.setState({ interest: event.target.value })
    document
      .querySelector(".controlMessage")
      .classList.remove("showCodeMessage")
  }

  _handleSubmit = async e => {
    e.preventDefault()

    let email = this.state.email

    let listFields = {
      FNAME: this.state.fullName,
      PHONE: this.state.phone,
      INTEREST: this.state.interest,
    }

    // console.log(listFields)

    const result = await addToMailchimp(email, listFields)
    // console.log(result)

    document.querySelector(".controlMessage").classList.add("showCodeMessage")

    if (result.result != "success") {
      document.querySelector(".controlMessage").innerHTML = `
        <span class="code error">Noget gik galt</span>
      <div>Udfyld venligst alle obligatoriske felter markeret med *.</div>`
    } else {
      document.querySelector(".controlMessage").innerHTML = `
        <span class="code succes">Tak for din tilmelding!</span>
      <div>Tjek venligst din email for yderligere instruktioner</div>`
    }

    // I recommend setting `result` to React state
    // but you can do whatever you want
    setTimeout(() => {
      document
        .querySelector(".controlMessage")
        .classList.remove("showCodeMessage")
    }, 4000)
  }

  render() {
    return (
      <div className="contactForm">
        <form className="contact_form" name="contact-form">
          <label htmlFor="fullName">Fulde navn *</label>
          <div className="input_wrapper">
            <input
              type="text"
              value={this.state.value}
              onChange={this.handleChangeFullName}
              id="fullName"
              required
            />
          </div>

          <label htmlFor="email">Email *</label>
          <div className="input_wrapper">
            <input
              type="text"
              value={this.state.value}
              onChange={this.handleChangeEmail}
              id="email"
              required
            />
          </div>

          <label htmlFor="phone">Telefon nummer *</label>
          <div className="input_wrapper">
            <input
              type="text"
              value={this.state.value}
              onChange={this.handleChangePhone}
              id="phone"
              required
            />
          </div>
          <label htmlFor="interest">Interesseområde</label>
          <div className="input_wrapper">
            <input
              type="text"
              value={this.state.value}
              onChange={this.handleChangeInterest}
              id="interest"
            />
          </div>
          <button onClick={this._handleSubmit}>Tilmeld</button>
        </form>
      </div>
    )
  }
}
