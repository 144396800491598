import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import myConfiguredSanityClient from "../../sanityClient"
import imageUrlBuilder from "@sanity/image-url"
// import ContactBox from "../components/contactBox"
import { Link, animateScroll as scroll } from "react-scroll"

// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MemberShipBox from "../components/membershipBoxNew"

const Membership = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityMembership {
        title
        description
        button
      }
      sanityMembership {
        title
        description
        button
        Info {
          name
          description
          image {
            hotspot {
              y
              x
            }
            asset {
              url
            }
          }
        }
      }
    }
  `)

  function getFocalPointsX(data) {
    let xValue = 0.5

    if (data.image.hotspot !== null) {
      xValue = data.image.hotspot.x
    }

    return xValue
  }

  function getFocalPointsY(data) {
    let yValue = 0.5

    if (data.image.hotspot !== null) {
      yValue = data.image.hotspot.y
    }

    return yValue
  }

  const builder = imageUrlBuilder(myConfiguredSanityClient)

  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <Layout>
      <SEO title="Medlemskab" description={data.sanityMembership.description} />

      <section className="membership">
        <div className="controlMessage"></div>

        <div className="activitySection">
          <div className="firstActivity">
            <div className="activityInfo">
              {/* <p className="linkTemp">
              {data.sanityAnnualMeeting.calendarInfo.date}
            </p> */}
              <h1 className="titleFadeIn">{data.sanityMembership.title}</h1>
              <p className="descriptionFadeIn">
                {data.sanityMembership.description}
              </p>
              <div className="whiteSpace"></div>
              <Link
                className="headerContactLink buttonTemp buttonFadeIn"
                activeClass="active"
                to="info"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                style={{ cursor: "pointer" }}
              >
                {data.sanityMembership.button}
              </Link>{" "}
              <div className="whiteSpace"></div>
            </div>

            <div className="contactWrapper fadeUp">
              {/* <p className="contactDate">
              {data.sanityAnnualMeeting.calendarInfo.date}
            </p> */}
              <MemberShipBox data={data.sanityMembership.title} />
            </div>
          </div>
        </div>

        <div className="infoSection" id="info">
          <div className="infoGrid">
            {data.sanityMembership.Info.map(function (info, i) {
              const image = info?.image?.asset?.url
              return (
                <div key={i} className="infoItem">
                  <div className="gatsby-image-container">
                    {image && (
                      <img
                        className="image"
                        src={urlFor(image)
                          .focalPoint(
                            getFocalPointsX(info),
                            getFocalPointsY(info)
                          )
                          .width(350)
                          .height(550)
                          .fit("crop")
                          .auto("format")
                          .url(image)}
                        alt={info.title}
                      />
                    )}
                  </div>
                  <div className="textContent">
                    <h2>{info.name}</h2>
                    <p>{info.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Membership
